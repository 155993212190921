<template>
    <!-- 使用Element UI的Dialog组件包裹整个内容形成弹窗 -->
    <el-dialog
      title="官网维护提示"
      :visible.sync="dialogVisible"
      width="400px"
      center
      :close-on-click-modal="false"
      custom-class="maintenance-dialog"
    >
      <div class="maintenance-content">
        <h1>官网正在升级维护中</h1>
        <p>敬请期待~</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closePage">关闭</el-button> <!-- 修改点击事件绑定的方法为closePage -->
      </span>
    </el-dialog>
  </template>
  
  <script>
  export default {
    name: "WebsiteMaintenance",
    data() {
      return {
        dialogVisible: true, // 控制弹窗是否显示，初始化为true，即页面加载时弹窗显示
      };
    },
    methods: {
      closePage() {
        window.close(); // 使用window.close()方法尝试关闭当前页面，在浏览器环境下有效
        this.dialogVisible = false; // 同时隐藏弹窗
      },
    },
  };
  </script>
  
  <style scoped>
  /* 对整个弹窗样式进行自定义，使其更炫酷 */
  .maintenance-dialog {
    background-color: rgba(0, 0, 0, 0.8); /* 半透明黑色背景 */
  }
  .maintenance-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #08c0b6bb; /* 文字颜色设为白色 */
  }
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* 文字添加阴影效果，使其更立体 */
  }
  p {
    font-size: 20px;
    margin-bottom: 30px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  .dialog-footer {
    text-align: center;
  }
  .el-button {
    background-color: #409eff; /* 按钮背景色 */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease; /* 按钮添加过渡效果，鼠标悬停时更有交互感 */
  }
  .el-button:hover {
    background-color: #66b1ff;
  }
  </style>